import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Angular10Project10';
  constructor(
    private http: HttpClient,) {

  }

  ngOnInit(): void {
    console.log('start11111111')

    // this.http.get(url, {withCredentials: true
    //   // responseType: 'blob',
    //   // headers: new HttpHeaders({ 'Authorization': LoopBackConfig.getAuthPrefix() + this.auth.getAccessTokenId() })
    // })//.pipe(retry(10))
    //   .subscribe((value) => {
    //     console.log('val', value)
    //   }, (err) => {
    //     console.log('err', err)
    //   }, () => {
    //     console.log('end')
    //   });
  }
}
